<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'NeighborhoodNeighborhoodAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="关键词" span="6">
          <vxe-input v-model="listQuery.param.keyWord" placeholder="请输入关键词" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="createUser" slot-scope="{row}">
        <CustomPic v-show="row.createUserAvatar" :src="row.createUserAvatar" :src-list="[row.createUserAvatar]" />
        <br v-show="row.createUserAvatar">
        {{ row.createUserName }}
      </template>
      <template slot="url" slot-scope="{row}">
        <a v-if="row.url" :href="row.url" target="_blank">
          <el-link type="primary" :underline="false">{{ row.url }}</el-link>
        </a>
      </template>
      <CustomPic slot="photos" slot-scope="{row}" :src="getPhotos(row.photos, 0)" :src-list="getPhotos(row.photos)" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-if="row.status === -1" v-permission="'NeighborhoodNeighborhoodShowButton'" size="small" type="text" @click="handleStatus(row, 1)">显示</el-button>
        <el-button v-else v-permission="'NeighborhoodNeighborhoodHideButton'" size="small" type="text" @click="handleStatus(row, -1)">隐藏</el-button>
        <el-button v-permission="'NeighborhoodNeighborhoodCheckButton'" size="small" type="text" @click="handleDetail(row.id)">查看</el-button>
        <el-button v-permission="'NeighborhoodNeighborhoodEditButton'" size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'Neighborhood',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      createTypeArray: ['小程序用户', '系统用户'],
      statusArray: { '-1': '隐藏', '0': '待审核', '1': '正常', '2': '拒绝' },
      listQuery: {
        param: {
          hasTenant: true
        }
      },
      tableColumns: [
        {
          title: '发布人类型',
          formatter: (row, column) => {
            switch (row.createType) {
              case 0:
                return '小程序用户'
              case 1:
                return '系统用户'
              default:
                return ''
            }
          },
          minWidth: 100
        },
        {
          title: '发布人',
          slot: 'createUser',
          minWidth: 100
        },
        {
          title: '图片',
          slot: 'photos'
        },
        {
          prop: 'address',
          title: '地址',
          minWidth: 150
        },
        {
          title: '话题',
          formatter: (row, column) => {
            return row.topics ? row.topics.split(',').join(' | ') : ''
          },
          minWidth: 200
        },
        {
          title: 'URL',
          slot: 'url',
          minWidth: 200
        },
        {
          prop: 'likeNumber',
          title: '点赞数',
          minWidth: 100
        },
        {
          prop: 'commentsNumber',
          title: '评论数',
          minWidth: 100
        },
        {
          title: '状态',
          formatter: (row, column) => {
            if (row.status in this.statusArray) {
              let text = this.statusArray[row.status]
              if (row.status === 2 && row.reason) {
                text += '：' + row.reason
              }
              return text
            } else {
              return ''
            }
          },
          minWidth: 150
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 180
        }
      ]
    }
  },
  methods: {
    ...mapActions(['neighborhoodPublicPageList', 'neighborhoodUpdate']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.neighborhoodPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    },
    handleStatus(row, status) {
      const statusText = status === -1 ? '隐藏' : '显示'
      this.$confirm(`确认${statusText}吗？`, '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id,
          createType: row.createType,
          photoIds: row.photoIds,
          content: row.content,
          url: row.url,
          topics: row.topics.split(' | ').join(','),
          tenant: row.tenant,
          address: row.address,
          latitude: row.latitude,
          longitude: row.longitude,
          status
        }
        this.neighborhoodUpdate(params).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('操作成功')
          this.handleSearch()
        }).catch(() => {})
      }).catch(() => {})
    },
    getPhotos(photos, index) {
      let photosArray = []
      if (photos) {
        photosArray = photos.split(',')
      }

      if (index === 0) {
        return photosArray.length ? photosArray[0] : ''
      } else {
        return photosArray
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
